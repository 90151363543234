<template>
  <div ref="el">
    <h4>{{ $t(`commands.list.delay.name`) }}</h4>
    {{ $t(`commands.list.delay.hint`) }}
    <input
      type="number"
      min="1"
      class="form-control"
      v-model="amount"
      @input="onChange"
    />
  </div>
</template>

<script>
import nodeMixin from "@/mixins/nodeMixin";

export default {
  mixins: [nodeMixin],
  data() {
    return {
      amount: 0,
    };
  },
  async mounted() {
    await this.$nextTick();
    this.getNodeId();
    this.getNodeData();
    this.amount = this.nodeData.data.storage;
  },
  methods: {
    onChange(value) {
      this.nodeData.data.storage = value.target.value;

      this.updateNodeData();
    },
  },
};
</script>

<style></style>
