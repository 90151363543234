<template>
  <div>
    <Breadcrumb
      :nameNav="nameNav"
      :itemFilter="itemFilter"
    />
    <!-- Table Container Card -->
    <b-card class="m-2" no-body>
      <b-card-header class="pb-50">
        <h5 class="text-capitalize">
          <span v-if="!isUpdating">{{ $t("commands.action_add") }}</span>
          <span v-else>{{ $t("commands.action_update") }}</span>
        </h5>
      </b-card-header>
      <b-card-body>
        <div class="mx-2 mb-2">
          <validation-observer ref="refFormObserver">
            <div class="m-2">
              <!-- ===================================== -->
              <b-row class="mb-2">
                <b-col md="12" class="mb-2">
                  <h3>{{ $t("commands.title_main") }}</h3>
                </b-col>
                <b-col cols="6" class="mb-1 mb-md-0">
                  <validation-provider
                    #default="validationContext"
                    name="Command name"
                    rules="required"
                  >
                    <label class="d-block">{{ $t("commands.fields.name.label") }}</label>
                    <b-form-input
                      v-model="customCommandName"
                      class="d-inline-block mr-1"
                      :placeholder="$t('commands.fields.name.placeholder')"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col cols="6" class="mb-1 mb-md-0">
                  <validation-provider
                    #default="validationContext"
                    name="Command description"
                    rules="required"
                  >
                    <label class="d-block">{{
                      $t("commands.fields.description.label")
                    }}</label>
                    <b-form-input
                      v-model="customCommandDescription"
                      class="d-inline-block mr-1"
                      :placeholder="$t('commands.fields.description.placeholder')"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col cols="6" class="mb-1 mb-md-0">
                </b-col>
                <b-col cols="6" class="mb-1 mb-md-0">
                  <b-form-checkbox
                    id="alarm_compatible"
                    v-model="alarmCompatible"
                    value="1"
                    unchecked-value="0"
                    name="alarm_compatible"
                    class="my-1 text-capitalize"
                    switch
                  >
                    {{ $t('commands.fields.alarm_compatible.label') }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <hr />
              <!-- ===================================== -->
              <b-row>
                <b-col md="12" class="d-flex flex-column ">
                  <div class="text-danger" v-if="flowchartError">
                    <span v-if="flowchartError.reason === 'connections_empty'">
                      - {{ $t(`commands.messages.${flowchartError.reason}`) }}
                    </span>
                  </div>
                  <div class="mt-1" v-if="missingNodeData.length">
                    <span class="text-danger">
                      - {{ $t('commands.messages.incomplete_node_config') }}:
                    </span>
                    <ul class="list-unstyled pl-1">
                      <li v-for="(error, index) in missingNodeData" :key="index">
                        {{ $t(`commands.list.${error.node.data.label}.name`) }}
                      </li>
                    </ul>
                  </div>
                </b-col>
                <b-col md="12">
                  <flowchart-component
                    v-if="commandOptions.length"
                    :command-options="commandOptions"
                    :config="flowchartConfig"
                    :missing-node-data="missingNodeData"
                    @flowchart-ready="onFlowchartReady"
                  />
                </b-col>
              </b-row>
            </div>
          </validation-observer>
          <b-row>
            <b-col md="12" class="d-flex justify-content-end">
              <b-button variant="success" @click="save">
                <span v-if="!isUpdating">{{ $t("commands.btns.create") }}</span>
                <span v-else>{{ $t("commands.btns.update") }}</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import useCustomCommandConfig from "./useCustomCommandConfig";
import FlowchartComponent from "./components/FlowchartComponent";
import { computed, ref } from "@vue/composition-api";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormCheckbox,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    vSelect,
    Breadcrumb,
    ValidationProvider,
    ValidationObserver,
    FlowchartComponent,
  },
  setup(_, context) {
    // start breadcrumb
    const nameNav = ref("commands.action_add");
    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    // end breadcrumb
    return {
      ...useCustomCommandConfig(),
      itemFilter,
      addItemFilter,
      nameNav,
    };
  },
};
</script>


