import { useToast } from "vue-toastification/composition";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";
import axios from "@axios";
import {
  computed,
  onMounted,
  ref,
  watch,
  reactive,
  getCurrentInstance,
  nextTick
} from "@vue/composition-api";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { image } from "vee-validate/dist/rules";
import useFlowchartValidations from "@/composables/flowchartValidations";

export default function useCustomCommandConfig() {

  const isUpdating = ref(false);
  const idCustomCommand = ref(null);
  const customCommandName = ref("");
  const customCommandDescription = ref("");
  const alarmCompatible = ref(0);
  const flowchartConfig = computed(() => ({
    alarm_compatible: alarmCompatible.value == 1
  }));
  const { refFormObserver, getValidationState } = formValidation();
  const toast = useToast();
  const { t } = useI18nUtils();
  const vm = getCurrentInstance().proxy;
  const MAX_NODE_CONNECTIONS = 1;
  const flowchartError = ref(null);
  const invalidNodes = ref([]);
  const missingNodeData = ref([]);

  const trans = {
    'commands.messages.success_created': t('commands.messages.success_created'),
    'commands.messages.success_updated': t('commands.messages.success_updated'),
  };

  const commandsFilter = ref({
    alarm_compatible: false
  });

  // Computed Properties ------------------------------------
  const commandOptions = computed(
    () => store.getters['command/getSelectedItems']
      .map(c => ({
        value: c.value,
        label: c.label,
        cmd: c.cmd,
        requirements: c.requirements
      })));

  // Methods ------------------------------------
  const onFlowchartReady = () => {
    if (router.currentRoute.params.id_command) {
      isUpdating.value = true;
      idCustomCommand.value = router.currentRoute.params.id_command
      loadUpdateData(idCustomCommand.value);
    }
  }

  const loadInitData = async () => store.dispatch('command/getAll');

  const loadUpdateData = async (idCustomCommand) => {
    const { data } = await store.dispatch("command/show", idCustomCommand);
    customCommandName.value = data.name;
    customCommandDescription.value = data.description;
    alarmCompatible.value = data.alarm_compatible;
    vm.$df.import(data.custom_cmd_data);
  };

  const validateFlowchart = ({ drawflow }) => {

    const nodes = Object
      .values(drawflow.Home.data)
      .sort((a, z) => a.pos_x - z.pos_x);

    if (nodes.length === 0) {
      flowchartError.value = { reason: 'layout_empty' };
      return false;
    }

    invalidNodes.value = [];
    missingNodeData.value = [];

    const { checkEmptyConnections, checkMaxConnections, checkNodeValidity } = useFlowchartValidations(nodes, invalidNodes, missingNodeData);

    nodes.forEach(node => {
      // checkEmptyConnections(node);
      checkMaxConnections(node, MAX_NODE_CONNECTIONS);
      checkNodeValidity(node);
    });

    if (invalidNodes.value.length) {
      flowchartError.value = invalidNodes.value[0];
    } else {
      flowchartError.value = null;
    }

    return invalidNodes.value.length === 0 && missingNodeData.value.length === 0;
  };

  const save = async () => {
    const validation = await refFormObserver.value.validate();
    const flowChartData = vm.$df.export();

    if (!validateFlowchart(flowChartData)) return;

    const data = {
      name: customCommandName.value,
      command: 'custom',
      description: customCommandDescription.value,
      alarm_compatible: alarmCompatible.value,
      custom_cmd_data: flowChartData
    }

    if (validation) {

      if (!isUpdating.value) {

        store.dispatch("command/add", data).then(data => {
          toast({
            component: ToastificationContent,
            props: {
              title: trans['commands.messages.success_created'],
              icon: "CheckIcon",
              variant: "success",
            },
          });

          isUpdating.value = true;
          idCustomCommand.value = data.data.id;

          router.push({ path: `/command/custom/${idCustomCommand.value}` });
        });

      } else {

        store.dispatch("command/edit", { id: idCustomCommand.value, params: data }).then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: trans['commands.messages.success_updated'],
              icon: "CheckIcon",
              variant: "success",
            },
          });
        });

      }
    }
  };

  // Hooks ------------------------------------
  onMounted(async () => await loadInitData());

  return {
    isUpdating,
    customCommandName,
    customCommandDescription,
    alarmCompatible,
    commandOptions,
    flowchartConfig,
    refFormObserver,
    getValidationState,
    required,
    save,
    onFlowchartReady,
    flowchartError,
    missingNodeData,
    commandsFilter
  };
}
